<template>
  <el-main class="projectinfo-container">
    <div class="inner-container">
      <div class="section-h2">
        <MainContain />
        {{ $t('Platform.Platform_Policy') }}
      </div>
      <div class="content-section">
        <section class="content-section">
          <div class="section-h3">{{ $t('Platform.Policy_Basis') }}</div>
          <div class="content-desc">{{ $t('Platform.Policy_Basis_Section1') }}</div>
          <div class="content-desc">{{ $t('Platform.Policy_Basis_Section2') }}</div>
        </section>
        <a
          class="a-link"
          href="https://www.ey.gov.tw/Page/5A8A0CB5B41DA11E/2c7d8d15-d002-417a-bdac-e66bfbd5e546"
          target="_blank"  
        >
          {{ $t('Platform.Sports_Everywhere') }}
        </a>   

        <section class="content-section">
          <div class="section-h3">{{ $t('Platform.Vision_Platform') }}</div>
          <strong>{{ $t('Platform.Vision_Platform_Section1') }}</strong>
          <div class="content-desc">{{ $t('Platform.Vision_Platform_Section2') }}</div>
        </section>
        <template v-if="language === 'zh-TW'">
          <section class="content-section">
            <div class="section-h3">{{ $t('Platform.Personal_info') }}</div>
            <div class="content-desc">
              {{ $t('Platform.Personal_info_Section1_1') }}
              <span
                class="download-text"
                @click="fetchGetDocumentAPI('consent_form', '串接運動數據公益平台告知事項暨個人運動資料提供同意書.docx')"
              >
                {{ $t('File.ConsentForm') }}
              </span>
              {{ $t('Platform.Personal_info_Section1_2') }}
            </div>
            <div class="content-desc">
              {{ $t('Platform.Personal_info_Section2_1') }}
              <span
                class="download-text"
                @click="fetchGetDocumentAPI('self_audit_checklist', '運動場域端（數據提供者）蒐集民眾運動資料之個人資料保護自我檢核表.docx')"
              >
                {{ $t('File.Self_auditChecklist') }}
              </span>
              {{ $t('Platform.Personal_info_Section2_2') }}
            </div>
            <div class="content-desc">
              {{ $t('Platform.Personal_info_Section3_1') }}
              <span
                class="download-text"
                @click="fetchGetDocumentAPI('compliance_handbook', '運動數據公益平台個資評估法遵使用手冊.pdf')"
              >
                {{ $t('File.Compliance_handbook') }}
              </span>
              {{ $t('Platform.Personal_info_Section3_2') }}
            </div>
          </section>
        </template>
      </div>        
    </div>
  </el-main>
</template>

<script>
import { mapGetters } from 'vuex'
import MainContain from '@/components/Accessibility/MainContain.vue'
import { DownloadFile } from '@/Mixins'

export default {
  name: 'ProjectInfo',
  components: {
    MainContain
  },
  mixins: [DownloadFile],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['language'])
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.projectinfo-container {
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
 
  .content-section{
    padding: 0.625rem;
    .content-desc{
      line-height: 1.5;
      padding: 0.625rem 0;
    }
  }

  .a-link{
    color: $link;
    line-height: 2;
    padding: 0.625rem;
    cursor: pointer;
  }

  .a-link:hover{
    font-weight: bold;    
  }
  .download-text{
    color: $link;
    cursor: pointer;
  }
  .download-text:hover{
    color: $text_dark;
  }
}
</style>
